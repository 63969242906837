import { DtiContext } from 'providers/DTIProvider'
import { useContext } from 'react'

const useDTIDataForm = () => {
  const { state, dispatch } = useContext(DtiContext)

  const setDataForm = (data) => {
    dispatch({ type: 'SET_DATA_DTI_FORM', payload: data })
  }

  return [state, setDataForm]
}

export default useDTIDataForm
