import dynamic from 'next/dynamic'
import { VideoDialog } from 'components/VideoDialog'
import { ValueProps } from 'pageSections/ValueProps'
import { PoliciesHero, PoliciesBody } from 'pageSections/Policies'
import { AboutUsHero } from 'pageSections/About/AboutUsHero'
import { HomeHeroV2 } from 'pageSections/Home/HomeHeroV2'
import { ProductHero } from 'pageSections/ProductHero/ProductHero'
import { HeroSection } from 'pageSections/HeroSection'
import { EmployeeStoriesHero } from 'pageSections/EmployeeStories/EmployeeStoriesHero'
import { MoloHero } from 'pageSections/MoloHero'
import { SectionFullBackground } from 'pageSections/Employee/SectionFullBackground'
import { TrustMarkersSection } from 'pageSections/TrustMarkersSection/TrustMarkersSection'
import { DebtQuiz } from 'pageSections/DebtQuiz/DebtQuiz'
import { HomeHeroArticle } from 'pageSections/Home/HomeHeroArticle'
import { NavArticlesSection } from 'pageSections/NavArticlesSection'
import { DTIProductCards } from 'pageSections/DTIProductCards'
import SliderLanding from 'pageSections/SliderLanding/SliderLanding'

const TrustMarkerGrid = dynamic(() =>
  import(/* webpackChunkName: "TrustMarkerGrid" */ 'pageSections/TrustMarkerGrid/TrustMarkerGrid')
)

const AboutCloserStatement = dynamic(() =>
  import(
    /* webpackChunkName: "AboutCloserStatement" */ 'pageSections/About/AboutCloserStatement/AboutCloserStatement'
  )
)
const AboutEmployeeResourceGroups = dynamic(() =>
  import(
    /* webpackChunkName: "AboutEmployeeResourceGroups" */ 'pageSections/About/AboutEmployeeResourceGroups/AboutEmployeeResourceGroups'
  )
)
const AboutUsLeadership = dynamic(() =>
  import(
    /* webpackChunkName: "AboutUsLeadership" */ 'pageSections/About/AboutUsLeadership/AboutUsLeadership'
  )
)
const CardGrid = dynamic(() =>
  import(/* webpackChunkName: "CardGrid" */ 'pageSections/CardGrid/CardGrid')
)
const CloserStatement = dynamic(() =>
  import(/* webpackChunkName: "CloserStatement" */ 'pageSections/CloserStatement/CloserStatement')
)
const FeaturedStory = dynamic(() =>
  import(
    /* webpackChunkName: "FeaturedStory" */ 'pageSections/MemberStories/FeaturedStory/FeaturedStory'
  )
)
const ImageGridSection = dynamic(() =>
  import(
    /* webpackChunkName: "ImageGridSection" */ 'pageSections/MemberStories/ImageGridSection/ImageGridSection'
  )
)
const MemberStoriesCarousel = dynamic(() =>
  import(
    /* webpackChunkName: "MemberStoriesCarousel" */ 'pageSections/MemberStories/MemberStoriesCarousel/MemberStoriesCarousel'
  )
)
const MemberStoriesCloserStatement = dynamic(() =>
  import(
    /* webpackChunkName: "MemberStoriesCloserStatement" */ 'pageSections/MemberStories/MemberStoriesCloserStatement/MemberStoriesCloserStatement'
  )
)
const ARCloser = dynamic(() =>
  import(/* webpackChunkName: "ARCloser" */ 'pageSections/ARCloser/ARCloser')
)
const Faqs = dynamic(() => import(/* webpackChunkName: "Faqs" */ 'pageSections/Faqs/Faqs'))
const ProblemStatement = dynamic(() =>
  import(
    /* webpackChunkName: "ProblemStatement" */ 'pageSections/ProblemStatement/ProblemStatement'
  )
)
const ByTheNumbers = dynamic(() =>
  import(/* webpackChunkName: "ByTheNumbers" */ 'pageSections/ByTheNumbers/ByTheNumbers')
)
const ProductCards = dynamic(() =>
  import(/* webpackChunkName: "ProductCards" */ 'pageSections/ProductCards/ProductCards')
)
const PhoneAndNumbers = dynamic(() =>
  import(/* webpackChunkName: "PhoneAndNumbers" */ 'pageSections/PhoneAndNumbers/PhoneAndNumbers')
)
const AboutUsVideo = dynamic(() =>
  import(/* webpackChunkName: "AboutUsVideo" */ 'pageSections/About/AboutUsVideo/AboutUsVideo')
)
const MoreResourcesCarousel = dynamic(() =>
  import(
    /* webpackChunkName: "MoreResourcesCarousel" */ 'pageSections/MoreResourcesCarousel/MoreResourcesCarousel'
  )
)
const TestimonialCarousel = dynamic(() =>
  import(
    /* webpackChunkName: "TestimonialCarousel" */ 'pageSections/TestimonialCarousel/TestimonialCarousel'
  )
)
const TestimonialCarouselV2 = dynamic(() =>
  import(
    /* webpackChunkName: "TestimonialCarouselV2" */ 'pageSections/TestimonialCarouselV2/TestimonialCarouselV2'
  )
)
const StoryBlocks = dynamic(() =>
  import(/* webpackChunkName: "StoryBlocks" */ 'pageSections/StoryBlocks/StoryBlocks')
)
const StoryBlocksAuthor = dynamic(() =>
  import(
    /* webpackChunkName: "StoryBlocksAuthor" */ 'pageSections/StoryBlocksAuthor/StoryBlocksAuthor'
  )
)
const AddressSection = dynamic(() =>
  import(/* webpackChunkName: "AddressSection" */ 'pageSections/AddressSection/AddressSection')
)
const PageTitle = dynamic(() =>
  import(/* webpackChunkName: "PageTitle" */ 'pageSections/PageTitle/PageTitle')
)
const StickySubNavWrapper = dynamic(() =>
  import(
    /* webpackChunkName: "StickySubNavWrapper" */ 'pageSections/StickySubNavWrapper/StickySubNavWrapper'
  )
)
const PressKitDownload = dynamic(() =>
  import(
    /* webpackChunkName: "PressKitDownload" */ 'pageSections/PressKitSection/PressKitDownload/PressKitDownload'
  )
)
const PaginatedStoryGrid = dynamic(() =>
  import(
    /* webpackChunkName: "PaginatedStoryGrid" */ 'pageSections/PaginatedStoryGrid/PaginatedStoryGrid'
  )
)
const PaginatedArticlesGrid = dynamic(() =>
  import(
    /* webpackChunkName: "PaginatedArticlesGrid" */ 'pageSections/PaginatedArticlesGrid/PaginatedArticlesGrid'
  )
)
const SmsDisclosures = dynamic(() =>
  import(/* webpackChunkName: "SmsDisclosures" */ 'pageSections/SmsDisclosures/SmsDisclosures')
)
const MoloSection = dynamic(() =>
  import(/* webpackChunkName: "MoloSection" */ 'pageSections/MoloSection/MoloSection')
)
const CloserStatementApp = dynamic(() =>
  import(
    /* webpackChunkName: "CloserStatementApp" */ 'pageSections/CloserStatementApp/CloserStatementApp'
  )
)
const EmployeeStoriesGrid = dynamic(() =>
  import(
    /* webpackChunkName: "EmployeeStoriesGrid" */ 'pageSections/EmployeeStoriesGrid/EmployeeStoriesGrid'
  )
)

const EmployeeStoriesPolaroidCollage = dynamic(() =>
  import(
    /* webpackChunkName: "EmployeeStoriesPolaroidCollage" */ 'pageSections/EmployeeStories/EmployeeStoriesPolaroidCollage/EmployeeStoriesPolaroidCollage'
  )
)
const TestimonialFullBackgroundCarousel = dynamic(() =>
  import(
    /* webpackChunkName: "TestimonialFullBackgroundCarousel" */ 'pageSections/TestimonialFullBackgroundCarousel/TestimonialFullBackgroundCarousel'
  )
)
const PartnersForm = dynamic(() =>
  import(/* webpackChunkName: "PartnersForm" */ 'pageSections/PartnersForm/PartnersForm')
)
const EmailForm = dynamic(() =>
  import(/* webpackChunkName: "EmailForm" */ 'pageSections/EmailForm/EmailForm')
)
const FeaturedPressCarousel = dynamic(() =>
  import(
    /* webpackChunkName: "FeaturedPressCarousel" */ 'pageSections/Press/FeaturedPressCarousel/FeaturedPressCarousel'
  )
)
const MultiSlider = dynamic(() =>
  import(/* webpackChunkName: "MultiSlider" */ 'pageSections/MultiSlider/MultiSlider')
)
const DTICalculator = dynamic(() =>
  import(/* webpackChunkName: "DTICalculator" */ 'pageSections/DTICalculator/DTICalculator')
)
const DTIRadialCalculator = dynamic(() =>
  import(
    /* webpackChunkName: "DTIRadialCalculator" */ 'pageSections/DTIRadialCalculator/DTIRadialCalculator'
  )
)
const DebtAssessmentSection = dynamic(() =>
  import(
    /* webpackChunkName: "DebtAssessmentSection" */ 'pageSections/DebtAssessmentSection/DebtAssessmentSection'
  )
)
const ArticlesCarousel = dynamic(() =>
  import(
    /* webpackChunkName: "ArticlesCarousel" */ 'pageSections/ArticlesCarousel/ArticlesCarousel'
  )
)
const SkinnyForm = dynamic(() =>
  import(/* webpackChunkName: "SkinnyForm" */ 'pageSections/SkinnyForm/SkinnyForm')
)
const ValuePropsFull = dynamic(() =>
  import(/* webpackChunkName: "ValuePropsFull" */ 'pageSections/ValuePropsFull/ValuePropsFull')
)
const TestimonialCarouselFull = dynamic(() =>
  import(
    /* webpackChunkName: "TestimonialCarouselFull" */ 'pageSections/TestimonialCarouselFull/TestimonialCarouselFull'
  )
)
const PromoCards = dynamic(() =>
  import(/* webpackChunkName: "PromoCards" */ 'pageSections/PromoCards/PromoCards')
)
const ProductDetailsTabs = dynamic(() =>
  import(
    /* webpackChunkName: "ProductDetailsTabs" */ 'pageSections/ProductDetailsTabs/ProductDetailsTabs'
  )
)

const DebtConsolidationSolutions = dynamic(() =>
  import(
    /* webpackChunkName: "DebtConsolidationSolutions" */ 'pageSections/DebtConsolidationSolutions/DebtConsolidationSolutions'
  )
)

const StatBarGrid = dynamic(() =>
  import(/* webpackChunkName: "StatBarGrid" */ 'pageSections/StatBarGrid/StatBarGrid')
)
const CloserStatementSection = dynamic(() =>
  import(
    /* webpackChunkName: "CloserStatementSection" */ 'pageSections/CloserStatementSection/CloserStatementSection'
  )
)
const SubnavSection = dynamic(() =>
  import(/* webpackChunkName: "SubnavSection" */ 'components/SubnavSection/SubnavSection')
)

const TrustpilotCarouselSection = dynamic(() =>
  import(
    /* webpackChunkName: "TrustpilotCarouselSection" */ 'pageSections/TrustpilotCarouselSection/TrustpilotCarouselSection'
  )
)

const PaginatedAuthorsList = dynamic(() =>
  import(
    /* webpackChunkName: "PaginatedAuthorsList" */ 'pageSections/PaginatedAuthorsList/PaginatedAuthorsList'
  )
)

const MemberOutcomeSection = dynamic(() =>
  import(
    /* webpackChunkName: "MemberOutcomeSection" */ 'pageSections/MemberOutcomeSection/MemberOutcomeSection'
  )
)

const ResultHero = dynamic(() =>
  import(/* webpackChunkName: "ResultHero" */ 'pageSections/ResultHero/ResultHero')
)

const ResultComparison = dynamic(() =>
  import(
    /* webpackChunkName: "ResultComparison" */ 'pageSections/ResultComparison/ResultComparison'
  )
)

const PaginatedArticlesList = dynamic(() =>
  import(
    /* webpackChunkName: "PaginatedArticlesList" */ 'pageSections/PaginatedArticlesList/PaginatedArticlesList'
  )
)

const CardInfo = dynamic(() =>
  import(/* webpackChunkName: "CardInfo" */ 'pageSections/CardInfo/CardInfo')
)

const ArticleListCarouselSection = dynamic(() =>
  import(
    /* webpackChunkName: "ArticleListCarouselSection" */ 'pageSections/ArticleListCarouselSection/ArticleListCarouselSection'
  )
)

const MainListArticlesCategory = dynamic(() =>
  import(
    /* webpackChunkName: "MainListArticlesCategory" */ 'pageSections/MainListArticlesCategory/MainListArticlesCategory'
  )
)

const PartnersRegisterForm = dynamic(() =>
  import(
    /* webpackChunkName: "PartnersRegisterForm" */ 'pageSections/PartnersRegisterForm/PartnersRegisterForm'
  )
)

// values in the componentMap need to match dropdown options in Contentful
export const pageSectionTypes = {
  AboutCloserStatement,
  AboutEmployeeResourceGroups,
  AboutUsHero,
  AboutUsLeadership,
  AboutUsVideo,
  AddressSection,
  ByTheNumbers,
  CardGrid,
  CloserStatement,
  EmployeeStoriesPolaroidCollage,
  EmployeeStoriesHero,
  Faqs,
  FeaturedPressCarousel,
  FeaturedStory,
  HeroSection,
  HomeHeroV2,
  ImageGridSection,
  MemberStoriesCarousel,
  MemberStoriesCloserStatement,
  MoloHero,
  MoreResourcesCarousel,
  PageTitle,
  PaginatedArticlesGrid,
  PaginatedStoryGrid,
  PoliciesBody,
  PoliciesHero,
  PressKitDownload,
  ProblemStatement,
  ProductCards,
  ProductHero,
  StickySubNavWrapper,
  StoryBlocks,
  TestimonialCarousel,
  ValueProps,
  VideoDialog,
  SmsDisclosures,
  EmailForm,
  PhoneAndNumbers,
  MoloSection,
  MultiSlider,
  CloserStatementApp,
  TrustMarkersSection,
  EmployeeStoriesGrid,
  TestimonialFullBackgroundCarousel,
  SectionFullBackground,
  StoryBlocksAuthor,
  PartnersForm,
  DTIProductCards,
  DTICalculator,
  DTIRadialCalculator,
  DebtAssessmentSection,
  ArticlesCarousel,
  SkinnyForm,
  ValuePropsFull,
  TestimonialCarouselFull,
  TestimonialCarouselV2,
  PromoCards,
  TrustMarkerGrid,
  ProductDetailsTabs,
  DebtConsolidationSolutions,
  StatBarGrid,
  ARCloser,
  CloserStatementSection,
  SubnavSection,
  TrustpilotCarouselSection,
  PaginatedAuthorsList,
  MemberOutcomeSection,
  DebtQuiz,
  ResultHero,
  ResultComparison,
  PaginatedArticlesList,
  CardInfo,
  HomeHeroArticle,
  NavArticlesSection,
  ArticleListCarouselSection,
  MainListArticlesCategory,
  PartnersRegisterForm,
  SliderLanding,
}

export default pageSectionTypes
