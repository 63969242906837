const normalizeCategoryName = (value) => {
  if (!value) return null
  return value
    .replace(/\w+/g, (w) => {
      return w[0].toUpperCase() + w.slice(1).toLowerCase()
    })
    .replaceAll('-', ' ')
}

export { normalizeCategoryName }
