import styles from './CtaCallButton.module.scss'
import { useState, useContext } from 'react'
import useIsSsr from 'hooks/useIsSsr'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Phone, X } from 'react-feather'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import { Typography, Box, AchieveTheme } from '@achieve/ascend'
import { AchieveLink } from 'components/AchieveLink'

function CtaCallButton({ children, phoneWrapper, variant }) {
  const [buttonCall, setButtonCall] = useState(false)
  const [buttonDisplay, setButtonDisplay] = useState(true)
  const phoneData = useContext(PhoneDataContext)
  const isSsr = useIsSsr()
  const mqHookResults = useViewportSmallerThan(BREAKPOINTS.lg)
  const isMobile = isSsr ? 'true' : mqHookResults

  const getMobileIcon = () => {
    return (
      <Phone
        width={24}
        height={24}
        color={
          variant === 'white'
            ? AchieveTheme?.ascend?.colors?.blue[500]
            : AchieveTheme?.ascend?.colors?.neutral?.[0]
        }
        onClick={handleCall}
      />
    )
  }

  const handleCall = () => {
    if (isMobile && !buttonCall) callToAssociatedNumber()
    setButtonCall(!buttonCall)
    if (!buttonDisplay) {
      setTimeout(() => {
        setButtonDisplay(true)
      }, 200)
    } else {
      setButtonDisplay(false)
    }
  }

  const callToAssociatedNumber = () => {
    if (phoneData?.phoneNumber) window.open(`tel:${phoneData.phoneNumber}`, '_self')
  }

  return phoneWrapper && phoneData?.phoneNumber ? (
    <>
      <div className={styles['call-cta-container']} data-open-button-call={buttonCall}>
        <div className={styles['button-container']} data-open-button-call={buttonCall}>
          {buttonDisplay && children}
        </div>
        {phoneData && (
          <Box
            className={styles['call-cta']}
            data-open-button-call={buttonCall}
            data-color-variant={variant}
          >
            {/*Empty div to work with space-between*/}
            {buttonCall && <div></div>}
            <div style={{ display: 'flex' }}>
              {!isMobile ? (
                getMobileIcon()
              ) : (
                <AchieveLink
                  className={styles['call-cta-link']}
                  target="_self"
                  href={`tel:${phoneData.phoneNumber}`}
                  noLink
                  track={{
                    list_name: 'PHONE CALL CLICK',
                    click_id: phoneData?.phoneNumber,
                    click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
                    click_url: phoneData?.phoneNumber,
                    click_type: 'Phone Click',
                    nav_link_section: 'Hero',
                    track_event: 'button_click',
                  }}
                >
                  {getMobileIcon()}
                </AchieveLink>
              )}

              {buttonCall && (
                <AchieveLink
                  className={styles['title-link']}
                  href={`tel:${phoneData.phoneNumber}`}
                  noLink
                  target="_self"
                  track={{
                    list_name: 'PHONE CALL CLICK',
                    click_id: phoneData?.phoneNumber,
                    click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
                    click_url: phoneData?.phoneNumber,
                    click_type: 'Phone Click',
                    nav_link_section: 'Hero',
                    track_event: 'button_click',
                  }}
                >
                  <Typography
                    className={styles['text']}
                    data-color-variant={variant}
                    variant="bodyLg"
                    fontWeight="regular"
                    onClick={() => callToAssociatedNumber()}
                  >
                    {phoneData?.phoneNumber}
                  </Typography>
                </AchieveLink>
              )}
            </div>
            {buttonCall && (
              <X
                className={styles['close-icon']}
                width={18}
                height={18}
                color={AchieveTheme?.ascend?.colors?.neutral?.[3]}
                onClick={handleCall}
              />
            )}
          </Box>
        )}
      </div>
    </>
  ) : (
    <>{children}</>
  )
}

export { CtaCallButton }
